// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

import "@fortawesome/fontawesome-free/js/all";
import "bootstrap"
import "../stylesheets/application"
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel";
import "jquery";
import "jquery.autocomplete"

window.jQuery = $;
window.$ = $;

document.addEventListener("turbolinks:load", () => {
	$('[data-toggle="tooltip]').tooltip()
	$('[data-toggle="popover]').popover()
})

document.addEventListener('turbolinks:load', function () {
	gtag('config', 'UA-71812374-1')
});

document.addEventListener("turbolinks:load", function () {
	$(document).ready(function () {
		var owl = $('.owl-carousel');
		owl.owlCarousel({
			items: 1,
			loop: true,
			margin: 10,
			autoplay: true,
			autoplayTimeout: 4000,
			animateIn: 'fadeIn',
			animateOut: 'fadeOut',
			dots: false
		});
	})
})

$(document).on('turbolinks:load', function () {
	var options = {
		serviceUrl: '/search',
		noCache: true,
		onSelect: function (event, ui) {
			$("#searchterm").val(ui.trim())
		}
	}
	var a = $('#searchterm').autocomplete(options)
})

$(document).on('turbolinks:load', function () {
	var options = {
		serviceUrl: '/search',
		noCache: true,
		onSelect: function (event, ui) {
			$("#mobile-searchterm").val(ui.trim())
		}
	}
	var a = $('#mobile-searchterm').autocomplete(options)
})

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
